<template>
  <div>
    <p class="textcustom">รายการชำระเงิน</p>
    <b-col
      class="px-0"
      v-for="(payment, pindex) in paymentDetailList"
      :key="payment.id + '-' + pindex"
    >
      <div
        @click="$emit('onPaymentSelected', payment, pindex)"
        :class="[
          'd-flex mt-2 payment-box',
          {
            'payment-box-active':
              paymentSelected.id == payment.id || payment.isSelected
          },
          {
            'payment-box-unactive':
              paymentSelected.id !== payment.id || !payment.isSelected
          }
        ]"
      >
        <b-col class="w-100 px-3 py-1 f-size-14">
          <div>
            {{ payment.transferDate | moment($formatDate) }} ({{
              payment.transferTime | moment($formatTime)
            }})
          </div>
          <div class="d-flex">
            <div>
              <img
                v-if="getBankAccount(payment.bankAccountId)"
                :src="getBankAccount(payment.bankAccountId).imageUrl"
                :alt="getBankAccount(payment.bankAccountId).bankName"
                class="bank-image"
              />
            </div>
            <div class="ml-2 text-gray-2">
              <div>
                {{
                  getBankAccount(payment.bankAccountId)
                    ? getBankAccount(payment.bankAccountId).accountName
                    : ""
                }}
              </div>
              <div>
                {{
                  getBankAccount(payment.bankAccountId)
                    ? getBankAccount(payment.bankAccountId).accountNo
                    : ""
                }}
              </div>
            </div>
          </div>
        </b-col>
        <div
          :class="[
            'p-4 payment-amount-box',
            { 'bg-purple-1': paymentSelected.id == payment.id },
            { 'bg-gray-1': paymentSelected.id !== payment.id }
          ]"
        >
          <div>
            {{ payment.amount | numeral("0,0.00") }}
          </div>
        </div>
      </div>
      <span v-if="v.$each.$iter[pindex].$error" class="text-error f-size-14"
        >กรุณากรอกข้อมูลให้ครบถ้วน</span
      >
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    paymentDetailList: {
      required: true,
      type: Array
    },
    bankAccountList: {
      required: true,
      type: Array
    },
    paymentSelected: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    }
  },
  methods: {
    getBankAccount(bankAccountId) {
      if (this.bankAccountList) {
        return this.bankAccountList.find(el => el.id == bankAccountId);
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped>
.textcustom {
  color: #575757;
  font-size: 15px;
}
.bank-image {
  height: 40px;
  width: 40px;
}
.payment-box-active {
  border: 1px solid rgb(113, 96, 196);
  height: auto !important;
  border-radius: 9px;
  cursor: pointer;
}
.payment-box-unactive {
  border: 1px solid gray;
  height: auto !important;
  border-radius: 9px;
  cursor: pointer;
}
.bg-purple-1 {
  background-color: rgb(113, 96, 196);
  border-radius: 0px 6px 6px 0px;
  color: white;
}
.bg-gray-1 {
  background-color: gray;
  color: white;
  border-radius: 0px 6px 6px 0px;
}
.text-error {
  color: red;
}
.payment-box {
  height: 72px;
}
.payment-amount-box {
  width: 80px;
}
</style>
