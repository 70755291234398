var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"textcustom"},[_vm._v("รายการชำระเงิน")]),_vm._l((_vm.paymentDetailList),function(payment,pindex){return _c('b-col',{key:payment.id + '-' + pindex,staticClass:"px-0"},[_c('div',{class:[
        'd-flex mt-2 payment-box',
        {
          'payment-box-active':
            _vm.paymentSelected.id == payment.id || payment.isSelected
        },
        {
          'payment-box-unactive':
            _vm.paymentSelected.id !== payment.id || !payment.isSelected
        }
      ],on:{"click":function($event){return _vm.$emit('onPaymentSelected', payment, pindex)}}},[_c('b-col',{staticClass:"w-100 px-3 py-1 f-size-14"},[_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(payment.transferDate,_vm.$formatDate))+" ("+_vm._s(_vm._f("moment")(payment.transferTime,_vm.$formatTime))+") ")]),_c('div',{staticClass:"d-flex"},[_c('div',[(_vm.getBankAccount(payment.bankAccountId))?_c('img',{staticClass:"bank-image",attrs:{"src":_vm.getBankAccount(payment.bankAccountId).imageUrl,"alt":_vm.getBankAccount(payment.bankAccountId).bankName}}):_vm._e()]),_c('div',{staticClass:"ml-2 text-gray-2"},[_c('div',[_vm._v(" "+_vm._s(_vm.getBankAccount(payment.bankAccountId) ? _vm.getBankAccount(payment.bankAccountId).accountName : "")+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.getBankAccount(payment.bankAccountId) ? _vm.getBankAccount(payment.bankAccountId).accountNo : "")+" ")])])])]),_c('div',{class:[
          'p-4 payment-amount-box',
          { 'bg-purple-1': _vm.paymentSelected.id == payment.id },
          { 'bg-gray-1': _vm.paymentSelected.id !== payment.id }
        ]},[_c('div',[_vm._v(" "+_vm._s(_vm._f("numeral")(payment.amount,"0,0.00"))+" ")])])],1),(_vm.v.$each.$iter[pindex].$error)?_c('span',{staticClass:"text-error f-size-14"},[_vm._v("กรุณากรอกข้อมูลให้ครบถ้วน")]):_vm._e()])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }