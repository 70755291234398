<template>
  <div>
    <p class="mt-2 textcustom">หมายเลขคำสั่งซื้อ: {{ invoiceNo }}</p>
    <p class="textcustom">
      ราคารวม: {{ orderTotalAmount | numeral("0,0.00") }}
    </p>
    <div class="mt-2 textcustom">
      <b-form-group label="ธนาคารปลายทาง" v-slot="{ ariaDescribedby }">
        <b-form-radio
          v-for="bank in bankAccountList"
          :key="bank.bankId"
          v-model="paymentSelected.bankAccountId"
          :aria-describedby="ariaDescribedby"
          :name="'bank-' + bank.bankName"
          :value="bank.id"
          :disabled="![2, 3].includes(orderStatus) || !permission"
        >
          <div>
            <img :src="bank.imageUrl" :alt="bank.imageUrl" class="bank-image" />
            <span class="ml-2 textcustom">{{ bank.bankName }}</span>
          </div>
        </b-form-radio>
      </b-form-group>
    </div>

    <div>
      <InputText
        textFloat="จำนวนเงิน"
        v-model="paymentSelected.amount"
        class="mr-2"
        placeholder="จำนวนเงิน"
        type="number"
        :isValidate="v.amount.$error"
        isRequired
        :v="v.amount"
        :isDisplay="![2, 3].includes(orderStatus) || !permission"
      />
    </div>
    <div class="picker-container">
      <div class="mt-3">
        <label class="mb-1 textcustom">วันที่โอน</label>
        <div class="input-container mr-2">
          <datetime
            :input-style="styleDatetime"
            v-model="paymentSelected.transferDate"
            placeholder="วว / ดด / ปป"
            ref="transferDate"
            value-zone="Asia/Bangkok"
            :disabled="![2, 3].includes(orderStatus) || !permission"
          >
          </datetime>
        </div>
      </div>
      <div class="mt-3">
        <label class="mb-1 textcustom">เวลาที่โอน</label>
        <div class="input-container mr-2">
          <datetime
            :input-style="styleDatetime"
            v-model="paymentSelected.transferTime"
            :disabled="![2, 3].includes(orderStatus) || !permission"
            type="time"
            placeholder="ชม. / นาที"
            value-zone="Asia/Bangkok"
            ref="transferTime"
          >
          </datetime>
        </div>
      </div>
    </div>
    <div class="mt-3 mr-2">
      <UploadFile
        textFloat="อัพโหลดภาพ"
        placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
        format="image"
        name="thumbnail"
        :fileName="paymentSelected.imageUrl"
        :disabled="![2, 3].includes(orderStatus) || !permission"
        text=""
        isRequired
        v-on:onFileChange="onImageChange"
        v-on:delete="onDeleteImage"
        :v="v.imageUrl"
      />
    </div>
    <div class="mt-2">
      <InputTextArea
        class=""
        type="text"
        textFloat="บันทึกเพิ่มเติม"
        placeholder="บันทึกเพิ่มเติม"
        name="text"
        rows="5"
        isRequired
        v-model="paymentSelected.note"
      />
    </div>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import UploadFile from "@/components/inputs/UploadFile";
import InputTextArea from "@/components/inputs/InputTextArea";
import { Datetime } from "vue-datetime";
import { mapGetters } from "vuex";
export default {
  components: {
    InputText,
    UploadFile,
    InputTextArea,
    datetime: Datetime
  },
  props: {
    invoiceNo: {
      required: true,
      type: String
    },
    orderTotalAmount: {
      required: true,
      type: Number
    },
    paymentSelected: {
      required: true,
      type: Object
    },
    bankAccountList: {
      required: true,
      type: Array
    },
    v: {
      required: true,
      type: Object
    },
    orderStatus: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      styleDatetime: {
        width: "100%",
        border: "none"
      },
      note: ""
    };
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      permission: "getPermission"
    })
  },
  methods: {
    onImageChange(img) {
      var reader = new FileReader();
      const objectURL = URL.createObjectURL(img);
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.paymentSelected.imageUrl = objectURL;
      };
    },

    onDeleteImage() {}
  }
};
</script>

<style scoped>
.textcustom {
  font-size: 15px;
  color: #575757;
}
.bank-image {
  width: 30px;
  height: 30px;
}
.input-container {
  display: flex;
  padding: 0.5rem;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
::v-deep .custom-input {
  color: #575757;
  background-color: white;
  border: 1px solid #bcbcbc !important;
  border-radius: 8px;
  height: 40px;
  padding: 5px 10px;
}
</style>
