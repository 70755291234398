<template>
  <div>
    <b-modal
      v-model="modalShow"
      title="รายละเอียดการชำระเงิน"
      hide-footer
      size="xl"
      header-class="header-payment"
    >
      <div>
        <div>
          <b-row>
            <b-col class="payment-list" cols="12" lg="4">
              <PaymentList
                :bankAccountList="bankAccountList"
                :paymentDetailList="paymentDetailList"
                :paymentSelected="paymentSelected"
                :v="$v.formApprovePayment"
                v-on:onPaymentSelected="onPaymentSelected"
                v-on:onAddNewPayment="onAddNewPayment"
              />
            </b-col>
            <b-col class="payment-image mt-4" cols="12" lg="4">
              <PaymentImage
                :paymentSelected="paymentSelected"
                :orderStatus="orderStatus"
                v-on:onDeleteImage="onDeleteImage"
              />
            </b-col>
            <b-col class="payment-detail mt-4" cols="12" lg="4">
              <PaymentDetailForm
                :bankAccountList="bankAccountList"
                :paymentSelected="paymentSelected"
                :invoiceNo="invoiceNo"
                :orderTotalAmount="orderTotalAmount"
                :orderStatus="orderStatus"
                :v="$v.formApprovePayment.$each.$iter[paymentSelectedIndex]"
              />
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="d-flex justify-content-between mt-4" v-if="orderStatus == 3">
        <div class="ml-2">
          <b-button
            class="w-100 btn-main-4"
            @click="onCheckformReject"
            :disabled="isDisable"
            >ปฏิเสธ</b-button
          >
        </div>
        <div class="ml-2">
          <b-button
            class="w-100 btn-main"
            @click="onCheckformApprove"
            :disabled="isDisable"
            >ยืนยัน</b-button
          >
        </div>
      </div>
      <div v-else>
        <div class="d-flex justify-content-end">
          <b-button class="btn-main" @click="hide">ตกลง</b-button>
        </div>
      </div>
    </b-modal>
    <ModalRejectPayment
      ref="modalRejectPayment"
      v-on:submitRejectPayment="onRejectPayment"
    />
    <ModalAlert
      ref="modalAlert"
      :text="modalMessage"
      v-on:hide="hadleModalAlertClose"
    />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
// import InputText from "@/components/inputs/InputText";
import { required } from "vuelidate/lib/validators";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import PaymentList from "../payment/PaymentList.vue";
import PaymentImage from "../payment/PaymentImage.vue";
import PaymentDetailForm from "../payment/PaymentDetailForm.vue";
import ModalRejectPayment from "../modal/ModalRejectPayment.vue";
import { mapGetters } from "vuex";
import * as moment from "moment/moment";

export default {
  components: {
    PaymentList,
    PaymentImage,
    PaymentDetailForm,
    ModalRejectPayment,
    // InputText,
    ModalAlert,
    ModalAlertError,
    ModalLoading
  },
  props: {
    orderId: {
      required: true,
      type: Number
    },
    invoiceNo: {
      required: true,
      type: String
    },
    orderTotalAmount: {
      required: true,
      type: Number
    },
    orderStatus: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      modalShow: false,
      paymentSelectedIndex: 0,
      paymentDetailList: [],
      formApprovePayment: [],
      formRejectPayment: {
        ReasonId: 0,
        Note: ""
      },
      bankAccountList: [],
      modalMessage: "",
      isDisable: false,
      paymentSelected: {}
    };
  },
  validations: {
    formApprovePayment: {
      $each: {
        bankAccountId: {
          required
        },
        amount: {
          required
        },
        paymentDate: {
          required
        },
        note: {},
        imageUrl: { required }
      }
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      permission: "getPermission"
    })
  },
  methods: {
    onAddNewPayment() {
      let paymentDetail = {
        id: this.paymentDetailList[this.paymentDetailList.length - 1].id - 1,
        bankAccountId: this.bankAccountList[0].id,
        amount: 0.0,
        transferDate: moment().format(),
        transferTime: moment().format(),
        imageUrl: "",
        transactionId: this.orderId,
        deleteImage: null,
        uploadImage: null,
        isSelected: true
      };
      this.paymentDetailList.push(paymentDetail);
      this.formApprovePayment.push({
        id: this.formApprovePayment[this.formApprovePayment.length - 1],
        bankAccountId: this.bankAccountList[0].id,
        amount: 0,
        paymentDate: moment().format(),
        note: "",
        imageUrl: ""
      });
      this.paymentSelected = this.paymentDetailList[
        this.paymentDetailList.length - 1
      ];
    },
    onDeleteImage() {
      this.paymentSelected.imageUrl = "";
    },
    onPaymentSelected(payment, paymentSelectedIndex) {
      this.paymentSelected = payment;
      this.paymentSelectedIndex = paymentSelectedIndex;
      this.paymentSelected.note = "";
    },
    getPaymentDetail: async function() {
      await this.$axios
        .get(`${this.$baseUrl}/api/transaction/${this.orderId}/transferList`)
        .then(async data => {
          if (data.data.detail.length > 0) {
            this.paymentDetailList = data.data.detail;
            this.paymentSelected = data.data.detail[0];
            this.paymentDetailList.forEach(element => {
              let paymentDate = `${element.transferDate.substring(0, 10)}T${
                element.transferTime
              }`;
              this.formApprovePayment.push({
                id: element.id,
                bankAccountId: element.bankAccountId,
                amount: element.amount,
                paymentDate: paymentDate,
                note: "",
                imageUrl: element.imageUrl
              });
            });
            await this.getBankList();
          } else {
            this.paymentDetailList = [];
            this.formApprovePayment = [];
            let paymentDetail = {
              id: 0,
              bankAccountId: 0,
              amount: 0.0,
              transferDate: moment().format(),
              transferTime: moment().format(),
              imageUrl: "",
              transactionId: 0,
              deleteImage: null,
              uploadImage: null
            };
            this.paymentDetailList.push(paymentDetail);
            this.formApprovePayment.push({
              id: 0,
              bankAccountId: 0,
              amount: 0,
              paymentDate: moment().format(),
              note: "",
              imageUrl: ""
            });
            this.paymentSelected = this.paymentDetailList[0];
            await this.getBankList();
          }
        });
    },
    getBankList: async function() {
      await this.$axios
        .get(
          `${this.$baseUrl}/api/transaction/bankaccount?transactionId=${this.orderId}`
        )
        .then(data => {
          this.bankAccountList = data.data.detail;
          if (this.paymentSelected.bankAccountId == 0) {
            this.paymentSelected.bankAccountId = this.bankAccountList[0].id;
          }
          this.paymentDetailList.forEach(element => {
            if (element.bankAccountId == 0) {
              element.bankAccountId = this.bankAccountList[0].id;
            }
          });
        });
    },
    onCheckformReject() {
      this.$refs.modalRejectPayment.show();
    },
    onCheckformApprove() {
      let paymentDate = `${this.paymentSelected.transferDate.substring(
        0,
        10
      )}T${this.paymentSelected.transferTime.substring(11, 19)}`;

      this.formApprovePayment[
        this.paymentSelectedIndex
      ].amount = this.paymentSelected.amount;
      this.formApprovePayment[
        this.paymentSelectedIndex
      ].paymentDate = paymentDate;
      this.formApprovePayment[
        this.paymentSelectedIndex
      ].imageUrl = this.paymentSelected.imageUrl;
      this.formApprovePayment[
        this.paymentSelectedIndex
      ].note = this.paymentSelected.note;

      this.$v.formApprovePayment.$touch();
      if (this.$v.formApprovePayment.$error) {
        return;
      }

      this.onApprovePayment();
    },
    onApprovePayment: async function() {
      this.isDisable = true;
      this.$refs.modalLoading.show();
      await this.$axios
        .put(`${this.$baseUrl}/api/transaction/${this.orderId}/approve`, {
          paymentList: this.formApprovePayment
        })
        .then(data => {
          this.isDisable = false;
          if (data.data.result == 1) {
            this.modalMessage = "ยืนยันการชำระเงินสำเร็จ";
            this.$refs.modalLoading.hide();
            this.$refs.modalAlert.show();
          } else {
            this.modalMessage = data.data.message;
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
    },
    onRejectPayment: async function(form) {
      this.isDisable = true;
      this.$refs.modalLoading.show();
      await this.$axios
        .put(`${this.$baseUrl}/api/transaction/${this.orderId}/reject`, form)
        .then(data => {
          this.isDisable = false;
          if (data.data.result == 1) {
            this.modalMessage = "ปฏิเสธการชำระเงินสำเร็จ";
            this.$refs.modalRejectPayment.hide();
            this.$refs.modalLoading.hide();
            this.$refs.modalAlert.show();
          } else {
            this.modalMessage = data.data.message;
            this.$refs.modalLoading.hide();
            this.$refs.modalAlertError.show();
          }
        });
    },
    hadleModalAlertClose() {
      this.hide();
      this.$emit("onRefreshData");
    },
    show: async function() {
      this.formApprovePayment = [];
      this.$v.formApprovePayment.$reset();
      await this.getPaymentDetail();
      this.modalShow = true;
    },
    hide() {
      this.modalShow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .header-payment {
  background: #eae2f3;
}
.payment-list,
.payment-image,
.payment-detail {
  height: auto;
  overflow: hidden;
  overflow-y: auto;
}
</style>
